import "./intro.scss";
import React, { useEffect, useRef } from 'react';
import { init } from 'ityped';
import { motion } from "framer-motion";



const Intro = (props) => {
    const { refs } = props;
    const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
    
    const onLoadedData = () => {
        setIsVideoLoaded(true);
        console.log("Video Loaded");
    };
    const textRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            setIsVideoLoaded(true);
        }, 1000)
        init(textRef.current, {
            showCursor: false,
            startDelay:500,
            backDelay:1500,
            backSpeed:100,
            strings: ["Software", "Web App", "Full-Stack"],
        });
   
    }, []);

    
    return (
        <div ref={refs} className="intro" id="intro">
            <div className="backgroundVid" >
            <video id="intro_vid" src="/assets/smoke.mp4" 
            autoPlay 
            loop 
            muted
            onLoadedData={onLoadedData}
            ></video>
          

          </div>
            { !isVideoLoaded ?
                <div className="loading"><motion.h1 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0, duration: 1 }}
                >LOADING...</motion.h1></div>
                : null
            }
            <div className="center">
                <div className="wrapper">
                    <motion.div className="drag_me"
                    initial={{ opacity: 0 }}
                    animate={{ opacity:  isVideoLoaded? 1 : 0}}
                    transition={{ delay: 2.8, duration: 1 }}
                    >Drag Me!</motion.div>
                
                    <motion.div className="avatarBorder"
                        initial={{ opacity: 0 }}
                        animate={{ opacity:  isVideoLoaded? 1 : 0}}
                        transition={{ delay: 2.5, duration: 1}}>
                        <motion.div className="avatar"
                            initial={{ opacity: 0 }}
                            animate={{ opacity:  isVideoLoaded? 1 : 0 }}
                            transition={{ delay: 2.8, duration: 1 }}
                        >
                            <motion.img
                                dragConstraints={{ left: -100, top: 0, right: 100, bottom: 0 }}
                                drag="x"
                                initial={{ opacity: 0 }}
                                animate={{ opacity:  isVideoLoaded? 1 : 0 }}
                                src="assets/test.png" alt="myself"></motion.img>

                        </motion.div>
                    </motion.div>

                    <div className="headerContainer">
                        
                        <motion.h1
                            initial={{ opacity: 0 }}
                            animate={{ opacity:  isVideoLoaded? 1 : 0 }}
                            transition={{ delay: 0.5, duration: 1 }}
                        >
                            Hi, I'm Tae
                        </motion.h1>
                        <motion.h2
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isVideoLoaded ? 1 : 0 }}
                            transition={{ delay: 1.8, duration: 1 }}
                        >
                            <span ref={textRef} className="red"></span> Developer
                        </motion.h2>
                    </div>

                    <motion.div className="downArrow"
                        initial={{ opacity: 0 }}
                        animate={{ opacity:  isVideoLoaded? 1 : 0 }}
                        transition={{ delay: 3.8, duration: 1 }}>
                        <a href="#about"><img src="./images/down_arrow.png" alt="down" /></a>
                       
                    </motion.div>
                    
                </div>

                
            </div>
            <div ></div>
        </div>
    )
}

export default Intro;
