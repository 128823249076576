import React, { useRef } from 'react'
import { motion } from "framer-motion";
import "./popup.scss"

function Popup({showPopup, setShowPopup}) {
    const popupRef = useRef();

    const closePopup = e => {
        if (popupRef.current === e.target){
            setShowPopup(false);
        }
    }
    return (
        <>

        {showPopup ? 
            <motion.div className="popup" ref={popupRef} onClick={closePopup}
            initial={{ opacity: 0 }}
            animate={{ opacity: showPopup? 1 : 0 }}
            >
                
                <div className="wrapper">
    
                    <img src="./images/thank_you.png" alt="Thank you" />
                </div>
                <div className="exit">
                    <button onClick={()=>setShowPopup(!showPopup)}>CLOSE</button>
                </div>
            </motion.div> 
        :null
        }  
        </>
    )
   
}

export default Popup
