import "./topbar.scss"

export default function Topbar({ menu, setMenu }) {

    // var prevScrollpos = window.pageYOffset;
    // window.onscroll = function() {
    //   var currentScrollPos = window.pageYOffset;
    //   if (prevScrollpos > currentScrollPos) {
    //     document.getElementById("topbar").style.top = "0";
    //   } else {
    //     document.getElementById("topbar").style.top = "-70px";
    //   }
    //   prevScrollpos = currentScrollPos;
    // }
    return (
        <div id="topbar" className={"topbar " + (menu && "active")}>
            <div className="wrapper">
                <div className="left">
                    <img src="./images/logo.png" alt="logo" />
                    <a href="https://taekjung.com" rel="noreferrer" className="logo">taeTheDev.</a>

                </div>

                <div className="middle">
                    <a href="#intro" className="navItems">Intro</a>
                    <a href="#about" className="navItems">About</a>
                    <a href="#skills" className="navItems">Skills</a>
                    <a href="#projectsHeader" className="navItems">Projects</a>
                    <a href="#contact" className="navItems">Contact</a>

                </div>
                <div className="email">
                    <a href="#contact" className="email">taethedev@gmail.com</a>
                </div>

                <div className="right">
                    <div className="menuButton" onClick={() => setMenu(!menu)}>
                        <span className="topLine"></span>
                        <span className="middleLine"></span>
                        <span className="bottomLine"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
