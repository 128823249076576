import {useState} from "react";
import classNames from "classnames";
import "./projectRight.scss";
import parse from 'html-react-parser';


export default function ProjectRight({ title, description, pic, github, website }) {
    const [imgActive, setImgActive] = useState(false);
    
    function imgActivate() {
        // setImgActive(!imgActive);
        website ? window.open(website, "_blank") : window.open(github, "_blank");
    }
    
    return (
        <div className="project_right" id="projects">
            <div className="container" >
                <div className="work">
                    <li className="content_left">
                        <div data-aos="fade-right"
                            data-aos-duration="1000" data-aos-offset="300" className="wrapper">
                            <div className="info">
                                <h1>{title}</h1>
                                <p>{parse(description)}</p>
                                <div className="button_wrapper">
                                    {website ?  
                                        <button className="btn__website" onClick={() => window.open(website, "_blank")}>
                                            TRY IT
                                        </button> 
                                    : null}
                                   
                                    {github? 
                                        <button className="btn__github" onClick={() => window.open(github, "_blank")}>
                                            GITHUB
                                        </button>
                                    : null }
                                </div>
                            </div>
                        </div>

                    </li>
                    <li className={classNames({
                        'content_right': true,
                        // 'img-active': imgActive
                    })} onClick={imgActivate}>
                        <img data-aos="fade-right" data-aos-duration="1000" data-aos-offset="500" src={pic} alt="work1" />
                    </li>
                </div>
            </div>
        </div>
    )
}
