import React from "react";
import "aos/dist/aos.css";

import "./aboutMe.scss";

function AboutMe() {
  return (
    <div className="AboutMe" id="about">
      <div className="top_mark">
        <div className="top_left"></div>
        <div className="top_right"></div>
      </div>
      <div className="container">
        <div className="left">
          <div className="left_content">
            <h1>A Little About Me...</h1>
            <p>
              A <span className="red">Software Engineer</span> with a B.S. in{" "}
              <span className="blue">Computer Science</span>. Currently working
              as a <span className="red">Front End Web Developer</span> and has
              served 4 years in the{" "}
              <span className="blue">United States of Air Force</span>. Worked
              on projects focusing on{" "}
              <span className="blue">full-stack development </span>in responsive web apps.{" "}
              <span className="red">Loves to code</span> and is a self-motivated
              developer. Personally likes <span className="red">indie games</span>.
            </p>
          </div>
        </div>
        <div className="right">
          <div className="right_content">
            <div
              data-aos="fade-down-left"
              data-aos-duration="1500"
              className="imgContainer"
            >
              <img src="images/myself2.png" alt="myself" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
