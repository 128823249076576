import emailjs from "emailjs-com"
import React, { useState } from 'react'
import "./contact.scss"
import Popup from "./Popup";

export default function Contact() {
    const [showPopup, setShowPopup] = useState(false)

    function sendEmail(e) {
        e.preventDefault();
        setShowPopup(!showPopup);
        emailjs.sendForm('service_91knbqa', 'template_l96zhh4', e.target, 'user_qatU9Q3qI9fVpdYGoPuhh').then(res=>{
            console.log(res);
        }).catch(err=> console.log(err));
    }

    return (
        <div className="contact" id="contact">
            <Popup showPopup={showPopup} setShowPopup={setShowPopup}/>
            <div className="container">
                <div data-aos="fade-down"
                    data-aos-duration="1000"
                    className="top">
                    <h1>CONTACT ME<span className="h1_blue">!</span></h1>
                    <div className="top_right">
                        <div className="social_media">
                            <a href="https://github.com/taethedev" target="_blank" rel="noreferrer" ><img src="./images/git.png" alt="github" /></a>
                            <a href="https://linkedin.com/in/taekjung" target="_blank"  rel="noreferrer"><img src="./images/ln.png" alt="linkedin" /></a>
                            <a href="https://facebook.com/taekjung91" target="_blank" rel="noreferrer" ><img src="./images/fb.png" alt="facebook" /></a>
                            <a href="https://codepen.io/taethedev" target="_blank" rel="noreferrer" ><img src="./images/codepen.png" alt="codepen" /></a>
                        </div>
                        <div className="resume">
                            {/* <img className="download" src="./images/downloads-32.png" alt="download" />
                            <a href="https://drive.google.com/file/d/12A13ekJwOtX9_Xl7hpZ_PSW0-5-w5Nfe/view?usp=sharing" target="_blank" rel="noreferrer">
                                <h1>Resume</h1>
                            </a> */}
                        </div>

                    </div>
                </div>
                <div className="middle">
                    <form onSubmit={sendEmail}>
                        <div className="form_container">
                            <div  data-aos="fade-right"
                                data-aos-duration="1000"
                                className="left">
                                <input type="text" name="name" placeholder="Name*" required/>
                                <input type="email" name="user_email" placeholder="Email*" required />
                                <input type="submit" value=' >>' className="submit" />
                            </div>
                            <div data-aos="fade-left"
                                data-aos-duration="1000"
                                className="right">
                                <textarea rows='4' name="message" placeholder="Feel free to say anything!" required />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <footer className="footer_container">
                Copyright &copy; 2022 Tae Jung · All Rights Reserved
            </footer>
        </div>
    )
}
