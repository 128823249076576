import "./career.scss"
export default function Career() {
    
    return (
       <div className="s__career">
            <div className="s__career-container">
                <div className="s__career-title">
                    <h1>My Adventurous Life...</h1>
                </div>
                <div className="s__career-content">
                    <ul className="s__career-ul">
                        {/* Insubuy */}
                        <li>
                            <div className="s__career-list-content-wrap">
                                <img src="images/meteor.png" className="s__career-list-marker" data-aos="fade-down-right" data-aos-offset="400" data-aos-duration="700"></img>
                                <div className="s__career-list-left">
                                    <h2 data-aos="fade-right">Jan. 2022 - CURRENT</h2>
                                    <h1 data-aos="fade-right">Insubuy <br></br>Front End Developer</h1>
                                </div>
                                <div className="s__career-list-right" data-aos="fade-up"
                                        data-aos-anchor-placement="center-bottom">
                                    <div className="s__career-list-p-wrap">
                                        <p><span className="p__text-bold">Working with a team of creative software engineers and UX designers</span> to create new features and contents for company owned websites</p>
                                        <p><span className="p__text-bold">Manage websites and create new features</span> which meets multi-language, cross-brower, and multi-platform compatibility.</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="img-bg" data-aos="fade-left"><img src="images/insubuy.png" style={{maxWidth: '400px'}} alt="insubuy" /></div>
                        </li>
                        {/* UMD */}
                        <li>
                            <div className="s__career-list-content-wrap">
                                <img src="images/meteor.png" className="s__career-list-marker" data-aos="fade-down-right" data-aos-offset="400" data-aos-duration="700"></img>
                                <div className="s__career-list-left">
                                    <h2 data-aos="fade-right">2018 - 2021</h2>
                                    <h1 data-aos="fade-right">University of Maryland - College Park</h1>
                                </div>
                                <div className="s__career-list-right" data-aos="fade-up"
                                        data-aos-anchor-placement="center-bottom">
                                    <div className="s__career-list-p-wrap">
                                        <p><span className="p__text-bold">Obtained B.S.</span> in Computer Science.</p>
                                        <p><span className="p__text-bold">Coursework</span> on Intro to AI, Database Design, Adv. Data Structures, Handheld Systems, Game Programming, System Architecture and Web Development.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="img-bg" data-aos="fade-left"><img src="images/umd.png" style={{maxWidth: '500px'}} alt="umd" /></div>
                        </li>
                        {/* UMGC */}
                        <li>
                            <div className="s__career-list-content-wrap">
                                <img src="images/meteor.png" className="s__career-list-marker" data-aos="fade-down-right" data-aos-offset="400" data-aos-duration="700"></img>
                                <div className="s__career-list-left">
                                    <h2 data-aos="fade-right">2017 - 2018</h2>
                                    <h1 data-aos="fade-right">University of Maryland Global Campus</h1>
                                </div>
                                <div className="s__career-list-right" data-aos="fade-up"
                                        data-aos-anchor-placement="center-bottom">
                                    <div className="s__career-list-p-wrap">
                                        <p><span className="p__text-bold">While working in the U.S. Air Force</span>, attended as a part-time student.</p>
                                        <p><span className="p__text-bold">Coursework</span> on GenEd and Intro to Programming.</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="img-bg" data-aos="fade-left"><img src="images/umgc.png" style={{maxWidth: '500px'}} alt="umgc" /></div>
                        </li>
                        {/* USAF */}
                        <li>
                            <div className="s__career-list-content-wrap">
                                <img src="images/meteor.png" className="s__career-list-marker" data-aos="fade-down-right" data-aos-offset="400" data-aos-duration="700"></img>
                                <div className="s__career-list-left">
                                    <h2 data-aos="fade-right">2014 - 2018</h2>
                                    <h1 data-aos="fade-right">U.S. Air Force - Aircraft Tech.</h1>
                                </div>
                                <div className="s__career-list-right" data-aos="fade-up"
                                        data-aos-anchor-placement="center-bottom">
                                    <div className="s__career-list-p-wrap">
                                        <p><span className="p__text-bold">Selected</span> to receive Below-The-Zone award (6 months early promotion).</p>
                                        <p><span className="p__text-bold">Troubleshot and fixed digital/hardware components</span> of the aircraft and its software using computer equipments.</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="img-bg" data-aos="fade-left"><img src="images/usaf.png" className="s__career-img--airforce" alt="usaf" /></div>
                        </li>
                    </ul>
                </div>
            </div>
       </div>
    )
}
