import { useState, useRef } from 'react'
import { 
  ProjectData1, 
  ProjectData2, 
  ProjectData3, 
  ProjectData4, 
  ProjectData5, 
  ProjectData6, 
  ProjectData7,
  ProjectData8,
  ProjectDataMain,

} from "./data/ProjectData"
import Topbar from "./components/topbar/Topbar";
import Intro from "./components/intro/Intro"
// import About from "./components/about/About";
import Career from "./components/about/Career";
import {MainProjectsHeader, SideProjectsHeader} from "./components/projects/ProjectsHeader";
import ProjectRight from "./components/projects/ProjectRight";
import ProjectLeft from "./components/projects/ProjectLeft";
import Contact from "./components/contact/Contact";
import Menu from "./components/menu/Menu";
import AboutMe from "./components/about/AboutMe";
import Skills from "./components/Skills/Skills";
import ScrollUp from "./components/scroll/Scroll-Up";
import AOS from 'aos';
import "./app.scss"
import useIntersection from './components/useIntersection';
import ProjectMain from './components/projects/ProjectMain';

export default function App() {

  const [menuActive, setMenuActive] = useState(false);
  const [clipActive, setClipActive] = useState(false);
 
  AOS.init();
  const ref = useRef();
  const refOff = useRef();
  const inViewport = useIntersection(ref, '0px')
  const inViewport2 = useIntersection(refOff, '0px')

  if (inViewport && !clipActive && !inViewport2) setClipActive(true);
  if (inViewport2 && clipActive && !inViewport) setClipActive(false);

  return (
    <div className="app">
      <Topbar menu={menuActive} setMenu={setMenuActive} />
      <Menu menu={menuActive} setMenu={setMenuActive} />
      <ScrollUp />
      <div onScroll={() => AOS.refresh()} className="sections">
        <Intro refs={refOff} />
        <AboutMe />
        {/* <About /> */}
        {/* <div ref={ref} className={clipActive ? 'clip-path-active' : 'clip-path1'}></div> */}
        <Career />
        <Skills />
        <MainProjectsHeader />
        <ProjectMain {...ProjectDataMain} />
        <SideProjectsHeader />
        <ProjectRight {...ProjectData8} />
        <ProjectLeft {...ProjectData7} />
        <ProjectRight {...ProjectData6} />
        {/* <ProjectRight {...ProjectData5} /> */}
        <ProjectLeft {...ProjectData4} />
        <ProjectRight {...ProjectData3} />
        <ProjectLeft {...ProjectData2} />
        <Contact />
      </div>
    </div>
  );
}
