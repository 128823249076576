// export const ProjectData1 = {
//     title: "Room Escape",
//     description: "A Simple puzzle game project created using Unity and C#. Goal is to solve puzzles in order to escape from each room.",
//     pic: "images/game.png",
//     github: "https://github.com/taethedev/RoomEscape",
//     website: ""
// };
export const ProjectDataMain = {
    title: "Magic Trade Journal",
    description: "For stock traders to post their trades in a personal account to keep a record and analyze their performance. <br> This is a my main project that I have put the most time into and is still on going in the hopes of making it a live service.<br>Includes user authentication with personalized app experience.  <br><br><ul><li><b>Full MERN Stack</b></li><li>Frontend: Create React App</li> <li>Backend: Express.js</li><li>Server: Mongo DB</li></ul>",
    pic: "images/tradersdoc.png",
    github: "",
    website: "https://tradejournal.taethedev.com"
};
export const ProjectData2 = {
    title: "Lost & Found",
    description: "Collaborative project developed using kotlin, android studios, and firebase. This app allows University of Maryland students to post lost items found within the campus.",
    pic: "images/lostandfound.png",
    github: "https://github.com/taethedev/LostAndFound",
    website: ""
};
export const ProjectData3 = {
    title: "Insurance Quote Form (Vue)",
    description: "A simple insurance quote form in React.<br><br><ul><li>Frontend: Create React App</li> <li>Backend: Express.js</li></ul>",
    pic: "images/code-challenge-vue-2.png",
    github: "https://github.com/taethedev/code-challenge-vue-2/tree/master/client",
    website: "https://code-challenge-vue-2.vercel.app/"
};
export const ProjectData4 = {
    title: "Insurance Quote Form (React)",
    description: "A simple insurance quote form in Vue. <br><br><ul><li>Frontend: Vue.js</li> <li>Backend: Express.js</li></ul>",
    pic: "images/Insubuy-Code-Challenge-react.png",
    github: "https://github.com/taethedev/code-challenge-reactjs",
    website: "https://code-challenge-reactjs.vercel.app/"
};
export const ProjectData6 = {
    title: "Dev's Toolbox",
    description: "Collection of simple tools for developers to use. <br><br><ul><li>Framework: Nuxt.js</li></ul>",
    pic: "images/devstoolbox.png",
    github: "",
    website: "https://www.devstoolbox.com/"
};
export const ProjectData7 = {
    title: "Magic Todo List",
    description: "Basic To-do list app with some cool looking magic. <br><br><ul><li>Frontend: JS, HTML, and CSS</li></ul>",
    pic: "images/magic-todo-list.png",
    github: "https://github.com/taethedev/MagicToDoList",
    website: "https://magic-todo-list.vercel.app/"
};
export const ProjectData8 = {
    title: "Magic Connect Four (Multi-Player)",
    description: "Multiplayer Connect Four game as SPA <br><br><ul><li>Frontend: Create React App</li> <li>Backend: Express.js / Socket.io</li></ul>",
    pic: "images/connect-four.png",
    github: "https://github.com/taethedev/MagicConnectFour",
    website: "https://magic-connect-four.vercel.app/"
};