import React from 'react'
import "./page404.scss"

function Page404() {
    return (
        <div className="page">
            <a href="https://taekjung.com"><img src="./images/404_pic.png" alt="404" /></a>
     
        </div>
    )
}

export default Page404
