import "./scroll-up.scss"
export default function ScrollUp() {
    var prevScrollpos = window.pageYOffset;
    
    window.onscroll = function() {
        const scrollUp = document.getElementById("scrollUp");
      var currentScrollPos = window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        scrollUp.style.right = "2%";
        document.getElementById("topbar").style.top = "0";
      } else {
        scrollUp.style.right = "-100px";
        document.getElementById("topbar").style.top = "-70px";
      }
      prevScrollpos = currentScrollPos;
    
      if (currentScrollPos == 0) {
        scrollUp.style.right = "-100px";
      }
    }
    return (
        <a href="#intro" id="scrollUp" className="scroll-up">
            <img src="images/scroll-up.png" alt="up" />
        </a>
    )
}
        
