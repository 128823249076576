import React, { useState } from 'react'
import "./skills.scss"
import handleViewport from 'react-in-viewport';

const Block = (props) => {
    const { forwardedRef } = props;
    return (
        <div className="viewport-block" ref={forwardedRef}>
        </div>
    );
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);

function Skills() {
    const [inView, setInView] = useState(false);
    return (
        <div id='skills' className="skills">
            <div className="container">
                <div className="header">
                    <div data-aos="zoom-in" data-aos-duration="1000">In My Tiny Brain...</div>
                </div>
                <div>
                    <span title='Vue.js' className='img-wrapper'><img src="/images/vue.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='React.js' className='img-wrapper'><img src="/images/reactjs.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    
                    <span title='JavaScript' className='img-wrapper'><img src="/images/js.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='CSS HTML' className='img-wrapper'><img src="/images/css_html.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Java' className='img-wrapper'><img src="/images/java.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='C#' className='img-wrapper'><img src="/images/cSharp.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='C++' className='img-wrapper'><img src="/images/cPP.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='C' className='img-wrapper'><img src="/images/c.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Kotlin' className='img-wrapper'><img src="/images/kotlin.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Python' className='img-wrapper'><img src="/images/python.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                </div>
                <div>
                    <span title='Node.js' className='img-wrapper'><img src="/images/node.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Spring' className='img-wrapper'><img src="/images/spring.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='MongoDB' className='img-wrapper'><img src="/images/mongodb.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='MySQL' className='img-wrapper'><img src="/images/mysql.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    {/* <span title='PostgreSQL' className='img-wrapper'><img src="/images/postgresql.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span> */}
                </div>
                <div>
                    <span title='Eclipse' className='img-wrapper'><img src="/images/eclipse.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Visual Studio' className='img-wrapper'><img src="/images/visual_studio.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                    <span title='Visual Studio Code' className='img-wrapper'><img src="/images/visual_studio_code.png" className={(inView ? 'view' : 'no-view')} alt="1" /></span>
                </div>
            </div>
            <ViewportBlock onEnterViewport={() => setInView(true)} onLeaveViewport={() => setInView(false)} />
        </div>
    )
}

export default Skills
