import "./menu.scss"

export default function Menu({menu, setMenu}) {
    return (
        <div className={"menu " + (menu && "active")}>
            <ul>
                <li onClick={()=> setMenu(!menu)}>
                    <a href="#intro">Intro</a>
                </li>
                <li onClick={()=> setMenu(!menu)}>
                    <a href="#about">About</a>
                </li>
                <li onClick={()=> setMenu(!menu)}>
                    <a href="#skills">Skills</a>
                </li>
                <li onClick={()=> setMenu(!menu)}>
                    <a href="#projectsHeader">Projects</a>
                </li>
                <li onClick={()=> setMenu(!menu)}>
                    <a href="#contact">Contact</a>
                </li>
                <div className="social">
                    <a href="https://github.com/taethedev" target="_blank" rel="noreferrer"><img src="./images/git.png" alt="github" /></a>
                    <a href="https://linkedin.com/in/taekjung" target="_blank" rel="noreferrer"><img src="./images/ln.png" alt="linkedin" /></a>
                    <a href="https://facebook.com/taekjung91" target="_blank" rel="noreferrer"><img src="./images/fb.png" alt="facebook" /></a>
                    <a href="https://codepen.io/taethedev" target="_blank" rel="noreferrer"><img src="./images/codepen.png" alt="codepen" /></a>
                </div>
                
            </ul>
        </div>
    )
}
