import "./projectsHeader.scss"

function MainProjectsHeader() {

    return (
        <div>
            <div className="projects__title"  id="projectsHeader">
                <h1>Main Project</h1>
            </div>
        </div>
    )
}
function SideProjectsHeader() {

    return (
        <div>
            <div className="projects__title">
                <h1>On the Side</h1>
            </div>
        </div>
    )
}
export {
    MainProjectsHeader,
    SideProjectsHeader
}