import {useState} from "react";
import classNames from "classnames";
import parse from 'html-react-parser';


export default function ProjectMain({ title, description, pic, github, website }) {
    const [imgActive, setImgActive] = useState(false);

    function imgActivate() {
        // setImgActive(!imgActive);
        website ? window.open(website, "_blank") : window.open(github, "_blank");
    }

    return (
        <div className="project_left" id="projects">
            <div className="container">
                <div className="work" style={{flexDirection:'column', alignItems:'center'}}>
                    <div style={{width:'100%', maxWidth:'600px'}} className={classNames({
                        'content_left': true,
                        // 'img-active': imgActive
                    })} onClick={imgActivate}>
                        <img id="1" data-aos="fade-left" data-aos-offset="500" data-aos-duration="1000" src={pic} alt="work1" style={{maxWidth:'600px', margin:'0 1em'}}/>
                    </div>
                    <div className="content_right">
                        <div className="wrapper" data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-offset="300"
                            >
                            <div className="info">
                                <h1 style={{color:'#dfd28a'}}>{title}</h1>
                                <p>{parse(description)}</p>
                                <div className="button_wrapper">
                                    {website ?  
                                        <button className="btn__website" onClick={() => window.open(website, "_blank")}>
                                            TRY IT
                                        </button> 
                                    : null}
                                   
                                    {github? 
                                        <button className="btn__github" onClick={() => window.open(github, "_blank")}>
                                            GITHUB
                                        </button>
                                    : null }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
